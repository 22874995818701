import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import Create1099s from './Create1099s';
import ProfitAndLoss from './ProfitAndLoss';
import InvoiceByType from './InvoiceByType';
import MaxUse from './MaxUse';
import CheckRegister from './CheckRegister';

export default function Billing(props) {
  const {match:{ url, path }} = props;
  return (
    <Switch>
      <Route path={`${path}/1099`} component={Create1099s}/>
      <Route path={`${path}/profit-and-loss`} component={ProfitAndLoss}/>
      <Route path={`${path}/invoice-by-type`} component={InvoiceByType}/>
      <Route path={`${path}/max-use`} component={MaxUse}/>
      <Route path={`${path}/check-register`} component={CheckRegister}/>
      <Redirect to={`${url}/profit-and-loss`}/>
    </Switch>
  );
}