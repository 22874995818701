import React, { useState, useEffect } from 'react';
import { useQuery } from 'skybolt-api';

import Loading from 'ui/Loading';
import Placeholder from 'ui/Placeholder';
import ThreadsList from 'ui/ThreadsList';



export default function UnreadMessages(props) {

  const data = useQuery(`{unreadThreads}`);

  console.log('unread', data);

  // `data.unreadThreads` will remove threads once they've been read. So keep a
  // local copy.
  const [unread, setUnread] = useState([]);
  useEffect(() => {
    if(!data) {
      return;
    }
    let all = [...unread, ...data.unreadThreads]
      .reduce((all, i) => {
        if(all.findIndex(j => j.threadId === i.threadId) === -1) {
          all.push(i);
        }
        return all;
      }, [])
      .sort((a, b) => a.dateUpdated > b.dateUpdated ? -1 : 1);
    setUnread(all);
  }, [data]);


  if(!data) {
    return <Loading style={{margin:40}}/>;
  }

  if(!unread.length) {
    return <Placeholder icon="message"/>;
  }

  return <ThreadsList threads={unread}/>;
}