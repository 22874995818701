import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateTalent } from 'actions';
import { debounce } from 'lodash';

import Title from 'ui/Title';
import Page from 'ui/Page';
import TextField from 'material-ui/TextField';


export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const talent = state.talents.all[talentId];
  
  return {
    talentId,
    notes: talent.notes || "",
  };
};

export const mapDispatchToProps = {
  updateTalent,
};


export class TalentNotes extends Component {
  state = {
    notes: ""
  }
  
  componentDidMount() {
    this.setState({notes:this.props.notes});
  }
  
  deferredUpdate = debounce(() => {
    this.props.updateTalent(this.props.talentId, {notes:this.state.notes});
  }, 1000)
  
  update = (notes) => {
    this.setState({notes}, this.deferredUpdate);
  }
  
  render() {
    return (
      <div>
      
        <Title>Notes</Title>
        
        <Page pad={true} style={{minHeight:400}}>
          <TextField
            fullWidth
            multiLine={true}
            underlineShow={false}
            hintText="Talent Notes"
            value={this.state.notes}
            onChange={(e, notes) => this.update(notes)}
          />
        </Page>
        
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentNotes);