import React from 'react';
import { Container, Text, Rule } from 'skybolt-ui';

export default function(props) {
  return (
    <Container>
      <div>
        <Text title>1099s</Text>
      </div>
      <Rule/>
    </Container>
  );
} 