import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  addCannedMessage,
  updateCannedMessage,
  loadCannedMessages,
  deleteCannedMessage,
  updateAgency,
} from 'actions';

import IconButton from 'material-ui/IconButton';
import Title, { Action } from 'ui/Title';
import Page from 'ui/Page';
import TextField from 'material-ui/TextField';
import Icon from 'ui/Icon';
import Wysiwyg from 'ui/Wysiwyg';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Card, InputText, Text } from 'skybolt-ui';

import "./index.css";


class AgencyMessaging extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadCannedMessages(this.props.agencyId);
  }

  render() {
    const { messages, agencyId, updateCannedMessage, muiTheme:{ palette } } = this.props;

    if(!messages) {
      //todo: loader
      return null;
    }

    return (
      <div className="form-cannedmessages" style={{padding:0}}>
        <Title>SMS Messaging</Title>

        <Card style={{padding:16}}>
          <div style={{maxWidth:600}}>
            <InputText
              title="Default SMS Message"
              value={this.props.defaultSMS}
              onChange={defaultSMS => {
                this.props.updateAgency(this.props.agencyId, {defaultSMS});
              }}
              hint={`${this.props.defaultSMS.length}/160`}
              error={this.props.defaultSMS.length > 160 ? `${this.props.defaultSMS.length}/160 Keep SMS messages below 160 character.` : null}
            />
            <Text paragraph light>
              Set the default SMS message for sent with new messages.<br/>
              This can be overwritten when composing messages. <br/>
              If no default is set, first 160 characters of the main message is used.
            </Text>
          </div>
        </Card>


        <Title
          actions={[<Action onClick={() => this.props.addCannedMessage(agencyId)}/>]}>
          Canned Messages
        </Title>

        {Object.keys(messages)
          .map(id => messages[id])
          .map(message =>
            <Page key={message.id}
              style={{
                display: 'flex',
                padding:"0 16px 16px 16px",
                marginBottom:16
              }}>

              <div style={{flex:1}}>
                <div>
                  <TextField
                    name="name"
                    floatingLabelText="Name"
                    value={message.name || ""}
                    onChange={(e, name) => updateCannedMessage(message.id, {name})}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    name="subject"
                    floatingLabelText="Subject"
                    value={message.subject || ""}
                    onChange={(e, subject) => updateCannedMessage(message.id, {subject})}
                  />
                </div>
                <div>
                  <div
                    style={{
                      fontSize:12,
                      paddingBottom:8,
                      marginTop:16,
                      color: palette.primary200,
                    }}>
                    Message
                  </div>
                  <Wysiwyg
                    html={message.body}
                    onChange={(e, body) => updateCannedMessage(message.id, {body})}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    name="sms"
                    floatingLabelText="SMS Message"
                    value={message.sms || ""}
                    onChange={(e, sms) => updateCannedMessage(message.id, {sms})}
                  />
                </div>
              </div>

              <div style={{flex:0}}>
                <IconButton
                  onClick={() => this.props.deleteCannedMessage(message.id)}>
                  <Icon name="trash" size={24} isReversed={true}/>
                </IconButton>
              </div>
            </Page>
          )
        }

        {Object.keys(messages).length === 0 &&
          <div>
            No canned messages.
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {agencyId, ...props} = ownProps;
  const defaultSMS = state.agencies.all[agencyId].defaultSMS || "";
  const messages = state.cannedMessages.byAgency[agencyId];
  return {
    agencyId,
    messages,
    defaultSMS,
    ...props
  };
};

const mapDispatchToProps = {
  addCannedMessage,
  updateCannedMessage,
  loadCannedMessages,
  deleteCannedMessage,
  updateAgency,
};

export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(AgencyMessaging));

